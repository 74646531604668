export default (data) => {
  return(
    {
      builderId: data._id ? data._id : "5fb2a5f3ea262c2e14e462ad",
      typeId: data.user ? "user" : "office",
      officeId: data.user ? data.user : data.office,
      primaryColor: data.primaryColor ? data.primaryColor : "#df0022",
      
      address: data.address ? data.address : "",
      email: data.email ? data.email : "carmenluz@hyhpropiedades.cl",
      facebook: data.facebook ? data.facebook : "https://facebook.com",
      twitter: data.twitter ? data.twitter : "https://twitter.com",
      instagram: data.instagram ? data.instagram : "https://instagram.com",
      favicon: data.favicon ? data.favicon : require("../images/icon.png"),      
      lat: data.lat ? data.lat : "-33.410167",
      lng: data.lng ? data.lng : "-70.655265",
      logo: data.logo ? data.logo : require("../images/logo-light.png"),
      logoDark: data.logoDark ? data.logoDark : require("../images/logo-dark.png"),
      movil: data.movil ? data.movil : "+56 9 4412 6401",
      phone: data.phone ? data.phone : "+56 9 4412 6401",
      footerText: data.footerText ? data.footerText : "Soy profesional en el área inmobiliaria, abocada al servicio de mis clientes, para que logren sus metas y sueños.",
      home:{
        hero: {
          background: data.home && data.home.hero && data.home.hero.background ? data.home.hero.background : require("../images/template-home-hero-background.jpg"),
          title: data.home && data.home.hero && data.home.hero.title ? data.home.hero.title : "¿Necesitas vender, arrendar o comprar una propiedad?"
        },        
        properties: {
          title: data.home && data.home.properties && data.home.properties.title ? data.home.properties.title : "Contamos con una selección exclusiva de propiedades.",
          maxProperties: data.home && data.home.properties && data.home.properties.maxProperties ? data.home.properties.maxProperties : 9,
          footer: data.home && data.home.properties && data.home.properties.footer ? data.home.properties.footer : "Estas son solo algunas de las propiedades que tenemos para ofrecerte",
          buttonText: data.home && data.home.properties && data.home.properties.buttonText ? data.home.properties.buttonText : "Ver más"
        },        
        about: {
          banner: {
            image: data.home && data.home.about && data.home.about.banner && data.home.about.banner.image ? data.home.about.banner.image : require("../images/template-home-about-hero-background.jpg"),
            title: data.home && data.home.about && data.home.about.banner && data.home.about.banner.title ? data.home.about.banner.title : "Soy profesional en el área inmobiliaria, abocada al servicio de mis clientes, para que logren sus metas y sueños.",
            subTitle: data.home && data.home.about && data.home.about.banner && data.home.about.banner.subTitle ? data.home.about.banner.subTitle : "Con mi experiencia en el mercado y rubro inmobiliario, logro enfocar y entender mejor las necesidades de mis clientes, entregando un servicio de excelencia.",
            buttonText: data.home && data.home.about && data.home.about.banner && data.home.about.banner.buttonText ? data.home.about.banner.buttonText : "Conózcanos"
          }
        },  
        services: {
          items: data.home && data.home.services && data.home.services.items.length ? data.home.services.items : [
            {
              title: "Ventas",
              description: "Me encargo de llevar el proceso completo de compraventa de forma profesional. No dudes en contactarme."
            },
            {
              title: "Arriendo",
              description: "Te ayudo a encontrar la propiedad que necesitas o en asesorarte en la búsqueda del arrendatario"
            }
          ]
        },  
        reviews: {
          items: data.home && data.home.reviews && data.home.reviews.items.length ? data.home.reviews.items : [
            {
              id: null,
              review: "Nuestros planes eran comprar 3 departamentos como un proyecto familiar, Carmen Luz nos dió toda la asesoría necesaria en este proceso tan importante. Estamos muy contentos.",
              author: "María José Baeza"
            },
            {
              id: null,
              review: "He confiado en 2 ocasiones el arriendo de mis oficinas a Carmen Luz, dando expectaculares resultados. Sin duda es una asesora integral muy comprometida con el servicio completo.",
              author: "Nancy Wityk"
            },
            {
              id: null,
              review: "Muy agradecido por la gestión de venta de mi departamento en Ñuñoa. Logró venderlo con rapidéz y a buen precio. Muy recomendable.",
              author: "Rolando Manríquez"
            },
            {
              id: null,
              review: "Carmen Luz Halabi, vendió una propiedad de mi madre en un plazo muy corto y en las optimas condiciones. La recomiendo totalmente.",
              author: "Oscar de La Barrera"
            },
            {
              id: null,
              review: "Carmen Luz, me vendió una propiedad en un plazo mínimo y perfectamente bien todo.",
              author: "Nancy Riveros"
            }
          ]
        }                    
      },
      about:{
        hero: {
          background: data.about && data.about.hero && data.about.hero.background ? data.about.hero.background : require("../images/template-about-hero-background.jpg"),
          title: data.about && data.about.hero && data.about.hero.title ? data.about.hero.title : "Perseverancia y profesionalismo al servicio de mis clientes."
        },
        history: {
          background: data.about && data.about.history && data.about.history.background ? data.about.history.background : require("../images/template-about-history-background.jpg"),
          description: data.about && data.about.history && data.about.history.description ? data.about.history.description : "<p>Soy profesional del área Financiera – Contable, que he emprendido en el mundo Inmobiliario, especializándome en Sector Oriente de Santiago de Chile, con un fuerte enfoque al servicio de las necesidades en Bienes Raíces de personas e instituciones.<p></p>Los comentarios de mis clientes y los resultados logrados avalan mi trabajo, siempre como foco el bienestar y satisfacción de las necesidades de las personas que contratan mis servicios.",
          title: data.about && data.about.history && data.about.history.title ? data.about.history.title : "Nuestra Historia"
        },
        description: {
          items: data.about && data.about.description && data.about.description.items.length ? data.about.description.items : [
            {
              title: "Misión",
              description: "Enfoque al servicio de las necesidades en bienes raíces de personas e instituciones. Busco desarrollar esta actividad sintiéndome feliz y mejorando cada día a la luz de mis valores."
            },
            {
              title: "Visión",
              description: "Entregar un servicio de excelencia, estar constantemente capacitándome y tener tecnología con estándar de calidad."
            },
            {
              title: "Valores",
              description: "Tener relaciones de confianza, honestidad y profesionalismo para el desarrollo de la actividad inmobiliaria. Valoro el tiempo de mis clientes, por lo cual ser pro-activa y eficiente es esencial."
            }
          ]
        },
        stats: {
          proffesionals: data.about && data.about.stats && data.about.stats.proffesionals ? data.about.stats.proffesionals : 70,
          properties: data.about && data.about.stats && data.about.stats.properties ? data.about.stats.properties : 1000,
          years: data.about && data.about.stats && data.about.stats.years ? data.about.stats.years : 50,
          transactions: data.about && data.about.stats && data.about.stats.transactions ? data.about.stats.transactions : 500
        },
        team: {
          visible: true,
          items: data.about && data.about.team && data.about.team.items.length ? data.about.team.items : [
            {
              avatar: require("../images/template-team-member-1.jpg"),
              cv: "De profesión Contador Auditor titulada de la Universidad de Chile. Profesional del Área Inmobiliaria con enfoque al servicio de las necesidades en bienes raíces de personas e instituciones.",
              email: "carmenluz@hyhpropiedades.cl",
              fullName: "Carmen Luz Halabi Halabi",
              phone: "+56 9 4412 6401"
            }                        
          ]
        },
        ubication: {
          title:  data.about && data.about.ubication && data.about.ubication.title ? data.about.ubication.title : "¿Necesitas vender, arrendar o comprar una propiedad? Somos tu socio perfecto"
        }
      },
      contact: {
        map: {
          title: data.contact && data.contact.map && data.contact.map.title ? data.contact.map.title : "",
          subTitle: data.contact && data.contact.map && data.contact.map.subTitle ? data.contact.map.subTitle : ""
        },
        title: data.contact && data.contact.title ? data.contact.title : "¿Dudas? ¿Consultas? Estamos aquí para ayudarlo.",
        subTitle: data.contact && data.contact.subTitle ? data.contact.subTitle : "Envienos un mensaje y uno de nuestros asesores se pondra en contacto a la brevedad"        
      },
    }
  )
}